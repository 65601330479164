<template>
  <!-- eslint-disable max-len -->
  <svg
    viewBox="0 0 24 24">
    <g>
      <path
        d="M20.487,14.631c-0.276,0-0.5,0.224-0.5,0.5l-0.001,8.295c0,0.276,0.224,0.5,0.5,0.5s0.5-0.224,0.5-0.5
        l0.001-8.295C20.987,14.854,20.764,14.631,20.487,14.631z"/>
      <path
        d="M20.487,5.295c1.113,0,1.853-1.399,1.853-2.325c0-1.036-0.831-1.879-1.853-1.879
        c-1.022,0-1.854,0.843-1.854,1.879C18.634,3.895,19.373,5.295,20.487,5.295z M20.487,2.091c0.47,0,0.853,0.394,0.853,0.879
        c0,0.582-0.479,1.325-0.853,1.325c-0.375,0-0.854-0.743-0.854-1.325C19.634,2.485,20.017,2.091,20.487,2.091z"/>
      <path
        d="M23.361,5.677c-0.055-0.047-0.12-0.082-0.189-0.102c-0.092-0.026-0.199-0.034-0.715-0.056L22.19,5.507
        c-0.453-0.032-0.845,0.217-1.059,0.64c-0.243,0.483-1.045,0.486-1.29,0c-0.215-0.422-0.621-0.671-1.058-0.639
        c-0.215,0.014-5.108,0.825-5.846,0.949c-0.5,0.085-0.836,0.502-0.817,1.014c0.019,0.505,0.394,0.905,0.89,0.952l4.69,0.438
        l0.001,4.786l0.535,9.808c0.015,0.267,0.235,0.473,0.499,0.473c0.009,0,0.018,0,0.027-0.001c0.275-0.015,0.487-0.251,0.472-0.526
        l-0.534-9.78V8.404c0-0.258-0.196-0.474-0.453-0.498l-5.144-0.463c1.472-0.248,5.507-0.911,5.743-0.937
        c0.027-0.006,0.073,0.037,0.102,0.093c0.273,0.541,0.862,0.877,1.537,0.877c0.676,0,1.265-0.336,1.538-0.877
        c0.028-0.056,0.069-0.1,0.103-0.093l0.288,0.013c0.102,0.004,0.214,0.009,0.306,0.012c0.118,0.409,0.359,1.838,0.206,6.508
        c-0.153,0-0.318,0.03-0.459,0.152c-0.103,0.089-0.188,0.265-0.194,0.399l-0.535,9.809c-0.016,0.275,0.196,0.512,0.472,0.526
        c0.303,0.033,0.512-0.196,0.526-0.472l0.513-9.391c0.157-0.001,0.325-0.025,0.47-0.15c0.104-0.091,0.187-0.267,0.192-0.404
        C24.172,6.366,23.583,5.865,23.361,5.677z"/>
      <path
        d="M15.986,18h-15V2h16c0.276,0,0.5-0.224,0.5-0.5S17.263,1,16.986,1h-1V0.519c0-0.276-0.224-0.5-0.5-0.5
        s-0.5,0.224-0.5,0.5V1h-5V0.519c0-0.276-0.224-0.5-0.5-0.5s-0.5,0.224-0.5,0.5V1h-8.5c-0.276,0-0.5,0.224-0.5,0.5v17
        c0,0.276,0.224,0.5,0.5,0.5h15.5c0.276,0,0.5-0.224,0.5-0.5S16.263,18,15.986,18z"/>
      <path
        d="M8.954,5.015c-0.815,0-1.478,0.663-1.478,1.478c0,0.095,0.011,0.187,0.028,0.276L4.815,8.766
        C4.569,8.584,4.269,8.473,3.94,8.473c-0.815,0-1.478,0.663-1.478,1.478s0.663,1.478,1.478,1.478s1.478-0.663,1.478-1.478
        c0-0.122-0.019-0.239-0.047-0.352L8.022,7.63c0.255,0.209,0.577,0.34,0.932,0.34c0.098,0,0.193-0.011,0.286-0.029l2.715,5.442
        c-0.207,0.254-0.336,0.574-0.336,0.926c0,0.814,0.663,1.478,1.478,1.478c0.814,0,1.478-0.663,1.478-1.478s-0.663-1.478-1.478-1.478
        c-0.098,0-0.193,0.011-0.285,0.029l-2.715-5.442c0.207-0.254,0.336-0.574,0.336-0.926C10.432,5.677,9.769,5.015,8.954,5.015z
        M3.94,10.429c-0.264,0-0.478-0.214-0.478-0.478S3.677,9.473,3.94,9.473s0.478,0.214,0.478,0.478S4.204,10.429,3.94,10.429z
        M8.476,6.492c0-0.263,0.214-0.478,0.478-0.478c0.263,0,0.478,0.214,0.478,0.478S9.218,6.97,8.954,6.97
        C8.69,6.97,8.476,6.756,8.476,6.492z M13.574,14.309c0,0.264-0.214,0.478-0.478,0.478s-0.478-0.214-0.478-0.478
        s0.214-0.478,0.478-0.478S13.574,14.046,13.574,14.309z"/>
      <path
        d="M14.986,23h-2v-2.374c0-0.276-0.224-0.5-0.5-0.5s-0.5,0.224-0.5,0.5V23h-3c-0.276,0-0.5,0.224-0.5,0.5
        S8.71,24,8.986,24h6c0.276,0,0.5-0.224,0.5-0.5S15.263,23,14.986,23z"/>
    </g>
  </svg>
</template>
